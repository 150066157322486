.inbox-item {
  display: block;
  padding: 0.75rem 1.25rem;

  &:not(:last-child) {
    border-bottom: 1px solid $light !important;
  }

  &.active {
    border-left: 1px solid $primary !important;
    background-color: lighten($primary, 74%);
  }

  .message-date {
    color: $text-muted;
    font-size: 12px;
  }

  .message-subject {
    margin-bottom: 0;
    color: $dark;
  }

  &.unread {
    .message-subject {
      color: $primary !important;
      font-weight: 600;
    }
  }
}

.message-box .message {
  .message-header {
    display: block;
    padding: 0.75rem 0;

    .message-date {
      color: $dark;
      font-size: 12px;
    }

    .message-subject {
      color: black;
      font-weight: bold;
      font-size: 1.25rem;
      margin-bottom: 0;
    }
  }

  .message-content {
    padding: 0.75rem 0;
  }
}

.message-box .attachments {
  .attachment-item {
    border: 2px solid $light;
    padding: 0.5rem 0.5rem;
    font-weight: 500;
  }
}

.message-box .empty-message {
  text-align: center !important;
  color: $light;
  font-size: 10rem;
}

.message-box .navbar {
  min-height: 42px;
}

.message-box-pagination {
  ul.pagination {
    margin: 0;
    padding: 0.75rem 1.25rem;
    border-top: 1px solid $light !important;
    border-bottom: 1px solid $light !important;

    .page-link {
      padding: 0.75rem 1.25rem;
      border: 0 !important;
      font-weight: 500;

      &:hover {
        background-color: white !important;
      }
    }

    .page-item.active .page-link {
      color: $primary !important;
      background-color: white !important;
      font-weight: 700;
    }
  }
}

.message-dropdown-menu {
  -webkit-box-shadow: 0px 18px 71px -43px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 18px 71px -43px rgba(0, 0, 0, 1);
  box-shadow: 0px 18px 71px -43px rgba(0, 0, 0, 1);
}
