//
// text.scss
//

// Decoration

.text-underline {
  text-decoration: underline;
}

// Weight

.font-weight-semibold {
  font-weight: $font-weight-semibold !important;
}
