main.container {
  padding-top: 1rem;
  padding: 1rem;
}

.pending-request-box {
  background-color: rgba(19, 0, 124, 0.04);
}

.divider {
  background: #e5e5e5;
  height: 1px;
  margin-bottom: 32px;
  margin-top: 32px;
}

.col-form-label {
  @extend h4;
  margin: 0 !important;
}

.request-withdrawal-btn {
  margin-top: 1.34rem !important;
}

.form-control {
  -webkit-appearance: none;
}

.lead-title {
  //@extend h3;
  font-weight: normal;
  //margin-top: $spacer;
  font-size: $h3-font-size;
}

.lead-content {
  font-weight: 500;
  font-size: 17px;
}

.btn {
  font-weight: bold;
}

.help-item {
  color: $gray-400;
}

.asteriskField {
  display: none;
}

// To have the footer at the bottom of all pages.
html {
  min-height: 100%;
  position: relative;
}

body {
  margin-bottom: 60px;
}

.footer {
  background-color: #f5f5f5;
  position: absolute;
  bottom: 0;
  width: 100%;
}
