ul.errorlist {
  color: $danger;
}

.invalid-feedback {
  display: block !important;
}

.form-control {
  // Nasir: The height of the inputs did not match the buttons and the problem seemed to be from the height calculated
  // in the Bootstrap _form.scss. Disabling that would fix the problem. But I'm not sure if this does break anything
  // else.

  height: auto;
}
