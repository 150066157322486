.sidebar {
  margin: 0;
  padding: 0;
  width: 200px;
  position: fixed;
  height: 100%;
  overflow: auto;
}

.sidebar a {
  display: block;
  color: black;
  padding: 10px;
  text-decoration: none;
}

.sidebar a.active {
  background-color: $primary;
  color: white;
}

.sidebar a:hover:not(.active) {
  background-color: $gray-200;
}

div.content {
  margin-left: 200px;
}

@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar a {
    float: left;
  }
  div.content {
    margin-left: 0;
  }
}

@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}

.intermediare-info-box {
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
}

.client-info-row {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.portfolio-value-column {
  width: 20%;
  font-size: 12px;
}
