//
// buttons.scss
//

.btn {
  .mdi {
    &:before {
      line-height: initial;
    }
  }
}

.btn-rounded {
  border-radius: 2em;
}

.btn-outline-primary {
  @include button-outline-variant(
    $primary,
    $primary,
    $active-background: $gray-100
  );
}

.input-group .btn {
  border-width: 0 !important;
}
