//
// nav-bordered
//

.nav-tabs.nav-bordered {
  border-bottom: none;

  .nav-item {
    margin-bottom: -2px;
    margin-top: 0.625rem;

    &:not(:last-child) {
      margin-right: 1.25rem;
    }
  }

  li {
    a {
      border: 0;
      padding: 0;
      padding-bottom: 0.625rem;
    }

    a.active {
      border-bottom: 2px solid $secondary;
    }
  }
}
