.accordion {
  border: 2px solid $gray-200;

  .accordion-header {
  }

  .accordion-body {
    .fund-name {
      font-size: 1rem;
      font-weight: normal;
    }
    padding-bottom: 1rem;
  }

  .accordion-title {
    @extend h4;
    font-size: 1rem;
    font-weight: normal;
  }

  .btn-outline-primary {
    border-color: $border-color;
  }
}
