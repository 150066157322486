//
// topbar.scss
//

.navbar-custom {
  padding: 0 30px;
  background-color: $bg-topbar;
  min-height: $topbar-height;
  border-bottom: 1px solid $gray-200;

  .topbar-left {
    background-color: $bg-topbar;
    height: $topbar-height;
    position: fixed;
    z-index: 1;
    width: $leftbar-width;
    text-align: center;
    top: 0;
    left: 0;

    .logo {
      line-height: $topbar-height;

      i {
        display: none;
      }
    }
  }

  .topbar-right-menu {
    li {
      float: left;
    }

    .nav-link {
      padding: 0;
      color: $gray-600;
      min-width: 32px;
      display: block;
      text-align: center;
      margin: 0 8px;
    }
  }
}

.message-box-item {
  position: relative;

  .message-box-icon {
    font-size: 22px;
    vertical-align: middle;
    line-height: 27px;
  }

  .unread-icon-badge-mobile {
    display: inline-block;
    position: absolute;
    font-weight: bold;
    top: 10px;
    right: 6px;
    border-radius: 50%;
    height: 7px;
    width: 7px;
    background: $danger;
    @include media-breakpoint-down(sm) {
      right: unset;
      left: 16px;
    }
  }

  .unread-icon-badge {
    position: absolute;
    top: 0px;
    right: -5px;
    padding: 2px 7px;
    border-radius: 50%;
    background: $danger;
    color: white;
    font-size: 10px;
    font-weight: bold;
  }
}
.dropdown-menu-center {
  right: auto;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
