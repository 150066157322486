.deadline {
  color: $red;
}

.portfolio-overview-box {
  border: 2px solid $border-color;
}

.card.card-account {
  border: 2px solid $border-color;
  &:hover {
    cursor: pointer;
    background-color: $gray-100;
    p {
      color: $dark !important;
    }
  }
}
