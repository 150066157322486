meter {
  margin: 0 auto 1em;
  width: 100%;
  height: 0.5em;

  /* Applicable only to Firefox */
  background: none;
  background-color: rgba(0, 0, 0, 0.1);
}

meter[value='1'] {
  background: $secondary;
}

meter[value='2'] {
  background: $secondary;
}

meter[value='3'] {
  background: $secondary;
}

meter[value='4'] {
  background: $secondary;
}
